<template>
  <div>
    <b-card>
      <!-- <b-modal
        ref="returnReasonModal"
        title="Select Return Reasons"
        no-close-on-backdrop
        no-close-on-esc
        @ok="addReturnReasons"
      >
        <b-form-group
          label="Select Return Reason"
          label-for="returnReasonSelect"
        >
          <b-form-select
            id="returnReasonSelect"
            v-model="selectedReturnReason"
            :options="returnReasonTypes"
            value-field="id"
            text-field="content"
            label="Select Return Reason"
          />
        </b-form-group>
      </b-modal> -->
      <div
        v-if="isLoading === true"
        class="text-center mb-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <b-form-group
        label="Livreur"
      >
        <validation-provider
          #default="{ errors }"
          name="Livreur"
        >
          <v-select
            v-model="delivery_man"
            :clearable="false"
            label="username"
            placeholder="Livreur"

            :options="delivery_mans"
            @input="getRoadmap"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div class="return-reason-buttons">
        <b-button
          v-for="reason in returnReasonTypes"
          :key="reason.id"
          class="mr-1 mt-1"
          :variant="selectedReturnReasonId === reason.id ? 'success' : 'secondary'"
          @click="selectReturnReason(reason)"
        >
          {{ reason.content }}
        </b-button>
      </div>
      <b-form-group
        label="Référence de colis"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="ref"
            placeholder="Scanner les colis retour"
            autofocus
            @change="checkOrder"
          />
        </b-input-group>
        <b-input-group class="input-group-merge mt-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchQueryForDeliveredOrder"
            placeholder="Scanner les colis livré"
            @change="checkOrderDelivered"
          />
        </b-input-group>
      </b-form-group>
    </b-card>

    <!-- table -->
    <div class="d-flex justify-content-between">
      <b-card>
        <h4> colis en cours : {{ orders.length }}</h4>
        <b-button
          class="btn btn-success mb-2"
          @click="deliverAll"
        >
          livrés tous les colis
        </b-button>
        <vue-good-table
          :columns="columns"
          :rows="orders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div>
              <span v-if="props.column.field === 'id'">
                <span> {{ props.row.id }}</span><br>
                <span
                  v-if="props.row.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="CheckIcon"
                    class="cursor-pointer mr-1"
                    size="16"
                    color="green"
                    @click="addItemToDeliveredList(props.row)"
                  />
                  <b-tooltip
                    title="Livré"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />
                </span>
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="CornerDownRightIcon"
                    class="cursor-pointer mr-1"
                    size="16"
                    color="red"
                    @click="addItemToReturnList(props.row)"
                  />
                  <b-tooltip
                    title="Retour"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}---show-icon`"
                  />
                </span>
              </span>

              <!-- Column: Common -->

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </div>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>

    </div>

    <div class="d-flex justify-content-between">

      <b-card>
        <h4> colis livré : {{ delivredOrders.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="delivredOrders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CornerDownRightIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="red"
                  @click="addItemFromDeliveredToInProgressList(props.row)"
                />
                <b-tooltip
                  title="Colis en cours"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />
              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>

      <b-card class="ml-1">
        <h4> colis retour : {{ returnOrders.length }}</h4>
        <vue-good-table
          :columns="columns_return"
          :rows="returnOrders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <span v-else-if="props.column.field === 'reasonTypeContent'">
              <span style="color:rgb(226, 0, 0) ; font-weight: bold">
                {{ props.row.reasonTypeContent }}
              </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="DeleteIcon"
                  color="red"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="addItemFromReturnOrdersToInProgressList(props.row)"
                />
                <b-tooltip
                  title="Colis en cours"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

        </vue-good-table>

      </b-card>

    </div>
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/email.svg')"
          alt="Meeting Pic"
          height="170"
        />
      </div>
      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center">

          <div class="my-auto">
            <b-card-title class="mb-25">
              Paiement de colis
            </b-card-title>
            <b-card-text class="mb-0">
              Total de tous les colis: <b-badge
                pill
                variant="primary"
                class="badge-glow"
              >
                {{ all }}
              </b-badge>
            </b-card-text>
          </div>
        </div>
        <!--/ metting header -->

        <!-- media -->
        <div class="d-flex flex-row align-items-center">
          <b-media
            class="mt-1"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="FolderPlusIcon"
                  size="18"
                  color="green"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Nombre de colis livré
              </h6>
              <div class="ml-2">
                <feather-icon
                  icon="BellIcon"
                  size="25"
                  class="text-success "
                  :badge="delivredOrders.length"
                  badge-classes="badge-success badge-glow"
                />
              </div>

            </b-media-body>
          </b-media>
          <b-media
            class="ml-5"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                  color="green"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Total de colis livré
              </h6>
              <div class="ml-2">
                <b-badge
                  pill
                  variant="success"
                  class="badge-glow"
                >
                  {{ priceDelivredOrder }} TND
                </b-badge>
              </div>

            </b-media-body>
            <b-media-body
              v-if="user.role ==='admin'"
              class="d-flex mt-1"
            >
              <h6 class="mb-0">
                Total de frais de livraison
              </h6>
              <div class="ml-2">
                <b-badge
                  pill
                  variant="success"
                  class="badge-glow"
                >
                  {{ totalDeliveryPrice }} TND
                </b-badge>
              </div>

            </b-media-body>
          </b-media>

        </div>
        <div class="d-flex">
          <b-media
            class="mt-1"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="FolderMinusIcon"
                  size="18"
                  color="red"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Nombre de colis retour
              </h6>
              <div class="ml-1">
                <feather-icon
                  icon="BellIcon"
                  size="25"
                  class="text-danger "
                  :badge="returnOrders.length"
                  badge-classes="badge-danger badge-glow"
                />
              </div>

            </b-media-body>
          </b-media>
          <b-media
            class="ml-4"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                  color="red"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Total de colis retour
              </h6>
              <div class="ml-1">
                <b-badge
                  pill
                  variant="danger"
                  class="badge-glow"
                >
                  {{ priceReturnOrder }} TND
                </b-badge>

              </div>

            </b-media-body>
            <br>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Nombre des colis échange
              </h6>
              <div class="ml-1">
                <b-badge
                  pill
                  variant="danger"
                  class="badge-glow"
                >
                  {{ exchangeOrders.length }}
                </b-badge>
                <div>
                  <p
                    v-for="element in exchangeOrders"
                    :key="element.id"
                  >
                    {{ element.id }}
                  </p>
                </div>
              </div>

            </b-media-body>
          </b-media>
        </div>

        <b-form-group class=" mt-2">
          <b-button
            variant="gradient-primary"
            class="ml-1 d-inline-block"
            :class="{ 'disabled-cursor': isLoading || !isPaymentValid }"
            :disabled="isLoading || !isPaymentValid"
            @click="validate"
          >
            <feather-icon icon="CheckIcon" />
            <span class="ml-1">Valider paiement</span>
          </b-button>
        </b-form-group>
      </b-card-body>
    </b-card>
  </div></template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,
  BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import retour from '@/assets/audio/retour.mp3'
import livré from '@/assets/audio/livré.mp3'
import notexist from '@/assets/audio/notexist.mp3'

export default {
  name: 'CreatePayment',
  components: {
    BImg,
    BBadge,
    BCardBody,
    BCardText,
    BMedia,
    BMediaAside,
    BAvatar,
    BTooltip,
    BMediaBody,
    BButton,
    ValidationProvider,
    VueGoodTable,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    // flatPickr,
    BPagination,
    vSelect,
    BCard,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      all: 0,
      delivery_mans: [],
      delivery_man: '',
      status: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'BL',
          },
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche gouvernorat',
          },
        },
        {
          label: 'Delegation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche delegation',
          },
        },
        {
          label: 'Telephone',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche telephone',
          },
        },
        {
          label: 'prix',
          field: 'price_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche prix',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      columns_return: [
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'BL',
          },
        },
        {
          label: 'motif retour',
          field: 'reasonTypeContent',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche retour',
          },
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche gouvernorat',
          },
        },
        {
          label: 'Delegation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche delegation',
          },
        },
        {
          label: 'Telephone',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche telephone',
          },
        },
        {
          label: 'prix',
          field: 'price_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche prix',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      role: '',
      orders: [],
      delivredOrders: [],
      returnOrders: [],
      roadmapsId: [],
      searchTerm: '',
      ref: '',
      searchQueryForDeliveredOrder: '',
      roadmaps: [],
      user: {},
      isLoading: false,
      retour,
      livré,
      notexist,
      selectedReturnReason: {
        id: null,
        content: '',
      },
      selectedReturnReasons: [],
      returnReasonTypes: [],
      selectedReturnReasonId: 17,
      selectedReturnReasonContent: null,
    }
  },
  computed: {
    priceDelivredOrder() {
      return this.delivredOrders.reduce((accumulator, item) => accumulator + Number(item.price_ttc), 0)
    },
    priceReturnOrder() {
      return this.returnOrders.reduce((accumulator, item) => accumulator + Number(item.price_ttc), 0)
    },
    totalDeliveryPrice() {
      return this.delivredOrders.reduce((accumulator, item) => accumulator + Number(item.price_delivery), 0)
    },
    isPaymentValid() {
      return (
        this.delivery_man !== '' && this.orders.length === 0
      )
    },
    exchangeOrders() {
      return (
        this.delivredOrders.filter(order => order.is_an_exchange === true)
      )
    },
  },
  created() {
    this.getDeliveryMans()
    this.getReturnReasonTypes()
    this.user = storeAuth.state.user
  },
  methods: {
    selectReturnReason(reason) {
      console.log('--------------', reason)
      this.selectedReturnReasonId = reason.id
      this.selectedReturnReasonContent = reason.content
    },
    /* openReturnReasonModal(result) {
      this.$refs.returnReasonModal.result = result
      this.$refs.returnReasonModal.show()
    }, */
    /* addReturnReasons() {
      // Access the result from the modal component
      const { result } = this.$refs.returnReasonModal
      // Check if a result is available
      if (result) {
        const selectedReason = this.returnReasonTypes.find(reason => reason.id === this.selectedReturnReason)

        if (selectedReason) {
          const reasonContent = selectedReason.content
          result.returnReason = reasonContent
          this.addItemToReturnList(result).then(() => {
            const reasonTypeId = selectedReason.id
            result.reasonTypeId = reasonTypeId
            result.reasonTypeContent = reasonContent
            this.ref = ''
            this.selectedReturnReason = {}
            this.playAudio(retour)
          })
        }
      }
      // Close the return reason modal
      this.$refs.returnReasonModal.hide()
    }, */
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },

    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },

    checkOrder() {
      const result = this.orders.find(element => element.id === (this.ref))
      if (result) {
        // this.openReturnReasonModal(result)
        result.reasonTypeContent = this.selectedReturnReasonContent
        this.addItemToReturnList(result).then(() => {
          result.reasonTypeId = this.selectedReturnReasonId
          this.ref = ''
          this.playAudio(retour)
        })
      } else {
        this.withTitle('Colis non exist')
        this.playAudio(notexist)
      }
    },
    checkOrderDelivered() {
      const result = this.orders.find(element => element.id === (this.searchQueryForDeliveredOrder))
      if (result) {
        this.addItemToDeliveredList(result).then(() => {
          this.searchQueryForDeliveredOrder = ''
          this.playAudio(livré)
        })
      } else {
        this.withTitle('Colis non exist')
        this.playAudio(notexist)
      }
    },
    async validate() {
      const obj = {
        roadmaps: this.roadmapsId,
        returnOrders: this.returnOrders,
        delivredOrders: this.delivredOrders,
        priceDelivredOrder: this.priceDelivredOrder,
        priceReturnOrder: this.priceReturnOrder,
        totalDeliveryPrice: this.totalDeliveryPrice,
        repository: storeAuth.state.user.repository,
        delivery_man: this.delivery_man.id,
        exchange_orders: this.exchangeOrders,
        orderReason: this.orderReason,
      }
      try {
        this.isLoading = true
        const response = await axios.post('/api/payments/create-payment/', obj)
        this.showToast('success', 'top-center', 'Paiement effectuée avec succés')
        this.$router.push(`/payments/show/${response.data}`)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async getDeliveryMans() {
      this.isLoading = true
      const { data } = await axios.get('/api/auth/drivers/eligible-for-payment/')
      this.delivery_mans = data
      this.isLoading = false
    },
    async getReturnReasonTypes() {
      const { data } = await axios.get('/api/return-vouchers/return-reasons/')
      this.returnReasonTypes = data
    },
    async addItemToReturnList(item) {
      const returnItem = item
      returnItem.reasonTypeContent = this.selectedReturnReasonContent
      returnItem.reasonTypeId = this.selectedReturnReasonId
      this.returnOrders.push(returnItem)
      const i = this.orders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.orders.splice(i, 1)
    },
    async addItemToDeliveredList(item) {
      // add the item to delivredOrders list
      this.delivredOrders.push(item)

      // search the index of the item that need to be removed from the orders list
      const i = this.orders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })

      // remove the searched item from orders list
      this.orders.splice(i, 1)
    },
    async addItemFromDeliveredToInProgressList(item) {
      // add the item to orders list
      this.orders.push(item)

      // search the index of the item that need to be removed from the delivredOrders list
      const i = this.delivredOrders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })

      // remove the searched item from delivredOrders list
      this.delivredOrders.splice(i, 1)
    },
    async addItemFromReturnOrdersToInProgressList(item) {
      this.orders.push(item)

      const i = this.returnOrders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.returnOrders.splice(i, 1)
    },
    async deliverAll() {
      this.delivredOrders = [...this.delivredOrders, ...this.orders]
      this.orders = []
    },
    async getRoadmap() {
      this.roadmaps = []
      this.roadmapsId = []
      this.orders = []
      this.delivredOrders = []
      this.returnOrders = []

      await axios.get('api/roadmaps/', { params: { delivery_man: this.delivery_man.id, is_payed: false } })
        .then(response => {
          this.roadmaps = response.data

          if (this.roadmaps.length !== 0) {
            for (let i = 0; i < this.roadmaps.length; i += 1) {
              this.roadmapsId.push(this.roadmaps[i].id)

              for (let e = 0; e < this.roadmaps[i].orders.length; e += 1) {
                const order = this.roadmaps[i].orders[e]

                // Check order status and add to corresponding arrays
                if (order.status === 3) {
                  this.orders.push(order)
                } else if (order.status === 4) {
                  this.delivredOrders.push(order)
                } else if (order.status === 5) {
                  this.returnOrders.push(order)
                }
              }
            }
          } else {
            this.orders = []
            this.delivredOrders = []
            this.returnOrders = []
          }

          // Calculate total
          this.all = this.delivredOrders.reduce((accumulator, item) => accumulator + Number(item.price_ttc), 0)
        })
        .catch(error => {
          console.error('Error fetching roadmaps:', error)
        })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.b-modal .modal-header .close,
  .b-modal .modal-footer {
    display: none !important;
  }
  .exchange-row {
    background-color: red;
    color: white;
    padding:50px
  }
</style>
